import { sanitizeImageUrl } from '@/utils/images';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import Image from 'next/image';
import Link from 'next/link';
import Address from './address';

type InfoProps = {
  clinicLogoURL: string;
  clinicName: string;
  clinicPageURL: string;
  isLicensedClinic: boolean;
  address?: string;
};

const Info = ({ clinicLogoURL, clinicName, clinicPageURL, isLicensedClinic, address }: InfoProps) => (
  <div data-cy="card-info">
    <div className="inline-flex items-center gap-2 " data-cy={`info-anchor-link-${clinicName?.replaceAll(' ', '-')}`}>
      {clinicLogoURL && (
        <Link rel="nofollow" href={clinicPageURL} legacyBehavior>
          <a rel="nofollow" href={clinicPageURL}>
            <div className="h-16 w-16 rounded-full border border-brandBlue-200 bg-white p-1 md:h-20 md:w-20" data-cy="card-logo">
              <span className="relative block h-full w-full">
                <Image className="rounded-full object-contain" src={sanitizeImageUrl(clinicLogoURL)} alt={clinicName ?? 'logo'} fill />
              </span>
            </div>
          </a>
        </Link>
      )}
      {clinicName && (
        <div className="inline-flex flex-1 items-center gap-2">
          <div className="flex flex-col">
            <Link rel="nofollow" href={clinicPageURL} legacyBehavior>
              <a rel="nofollow" href={clinicPageURL}>
                <div className="flex hover:underline hover:underline-offset-2">
                  <h2 className="text-lg font-semibold text-primary lg:text-xl" data-cy="card-name">
                    {clinicName}
                  </h2>
                  {isLicensedClinic && (
                    <span className="ml-3 mr-8 mt-1" data-cy="affiliated-clinic-badge">
                      <CheckBadgeIcon className="h-5 w-5 fill-primary-pressed" />
                    </span>
                  )}
                </div>
              </a>
            </Link>
            <Address address={address} />
          </div>
        </div>
      )}
    </div>
  </div>
);

export default Info;
