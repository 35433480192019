import type { GeoCoordinates, MedicalClinic, PostalAddress } from 'schema-dts';
import { jsonLdOpeningHoursSpecification } from './jsonLdOpeningHoursSpecification';
import { jsonLdMedicalTestFromSpecialization } from './jsonLdMedicalTest';
import { jsonLdPostalAddressFromStructuredAddress } from './jsonLdPostalAddress';
import { PREFIXED_CALLCENTER_TELEPHONE_NUMBER } from '@/costants';
import { AggregatorSpeciality, AggregatorWorkgroup } from '@/graphql/types';
import { AlgoliaClinicV2 } from '@/types/algolia-types';

export const jsonLdMedicalClinicFromWorkgroup = (workgroup: AggregatorWorkgroup): MedicalClinic => {
  const startingPrice = workgroup.specializations?.reduce((min, specialization) => {
    if (!specialization.price) return min;
    return specialization.price < min ? specialization.price : min;
  }, 0);

  let geo: GeoCoordinates | undefined;
  if (workgroup?.address?.location?.type === 'Point') {
    const lat = workgroup.address.location.coordinates?.[0];
    const lng = workgroup.address.location.coordinates?.[1];
    if (lat && lng) {
      geo = {
        '@type': 'GeoCoordinates',
        latitude: lat,
        longitude: lng,
      };
    }
  }

  const openingHoursSpecification = workgroup.openingHours?.flatMap(jsonLdOpeningHoursSpecification);
  const url = `https://elty.it/centri-medici/${workgroup?.inclinicSlug}`;

  return {
    '@type': 'MedicalClinic',
    name: workgroup.name,
    telephone: PREFIXED_CALLCENTER_TELEPHONE_NUMBER,
    url,
    '@id': url,
    openingHoursSpecification: openingHoursSpecification && openingHoursSpecification.length > 0 ? openingHoursSpecification : undefined,
    image: workgroup.logoUrl ?? undefined,
    isAcceptingNewPatients: true,
    priceRange: startingPrice && startingPrice !== 0 ? `A partire da ${startingPrice / 100}€` : undefined,
    availableService: workgroup.specializations?.map(jsonLdMedicalTestFromSpecialization),
    address: workgroup.address ? jsonLdPostalAddressFromStructuredAddress(workgroup.address) : undefined,
    geo,
  };
};

export const jsonLdMedicalClinicFromAlgoliaClinic = (clinic: AlgoliaClinicV2): MedicalClinic => {
  const address: PostalAddress = {
    '@type': 'PostalAddress',
    streetAddress: clinic?.address,
    addressLocality: clinic?.city,
    postalCode: clinic?.cap,
    addressCountry: 'IT',
  };

  const { lat, lng } = clinic._geoloc || {};
  const geo: GeoCoordinates | undefined =
    lat && lng
      ? {
          '@type': 'GeoCoordinates',
          latitude: lat,
          longitude: lng,
        }
      : undefined;

  const startingPrice = clinic.specializations.price;

  return {
    '@type': 'MedicalClinic',
    name: clinic.name,
    image: clinic.logoUrl.length > 0 ? clinic.logoUrl : undefined,
    address,
    geo,
    isAcceptingNewPatients: true,
    availableService: jsonLdMedicalTestFromSpecialization(clinic.specializations as AggregatorSpeciality),
    telephone: PREFIXED_CALLCENTER_TELEPHONE_NUMBER,
    priceRange: startingPrice && startingPrice !== 0 ? `A partire da ${startingPrice / 100}€` : undefined,
  };
};
