import { OrderType } from '@/api/algolia';
import Select, { SelectOption } from './select';
import { BarsArrowDownIcon } from '@heroicons/react/24/solid';
import { FilterStates } from '..';
import { useRouter, useSearchParams } from 'next/navigation';
import { getEnumValueFromString, getStringFromQueryParam } from '@/utils/string';

type OrderFilterProps = {
  filterStates: FilterStates;
  setFilterStates: (filterStates: FilterStates) => void;
  applyFilters: boolean;
};

export const orderFilterOptions: SelectOption[] = [
  {
    value: 'Vicinanza (dal più vicino)',
    key: OrderType.DistanceAsc,
  },
  {
    value: 'Prezzo (dal più economico)',
    key: OrderType.PriceAsc,
  },
];

const OrderFilter = ({ filterStates, setFilterStates }: OrderFilterProps) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const getOrderFilterValue = () => {
    const orderFilter = getEnumValueFromString(OrderType, getStringFromQueryParam(searchParams?.get('filterOrder') ?? undefined));
    return orderFilterOptions.find(o => o.key === orderFilter) ?? orderFilterOptions[0];
  };

  const getOrderFilterKey = (value: string) => {
    return orderFilterOptions.find(opt => opt.value === value)?.key ?? OrderType.DistanceAsc;
  };

  const handleOrderFilter = (value: string) => {
    setFilterStates({ ...filterStates, order: getEnumValueFromString(OrderType, value) });
    const newParams = new URLSearchParams(searchParams?.toString());
    newParams.set('filterOrder', getOrderFilterKey(value));
    window.history.pushState(null, '', `?${newParams.toString()}`);
  };

  return (
    <Select
      name="service-filter-select"
      options={orderFilterOptions}
      onSelect={handleOrderFilter}
      initialSelectedItem={getOrderFilterValue()}
      placeholder="Ordina per"
    >
      <BarsArrowDownIcon />
    </Select>
  );
};

export default OrderFilter;
