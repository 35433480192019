import { AlgoliaClinicV2 } from '@/types/algolia-types';
import CardBody from './cardBody';
import CardFooter from './cardFooter';

type CardProps = {
  item: AlgoliaClinicV2;
};

const Card = ({ item }: CardProps) => (
  <div className="h-fit rounded-lg border bg-neutral-surface shadow-md hover:shadow-[1px_1px_25px_1px_#D3E0FB]" data-cy="card">
    <div className="min-h-[720px] px-4 py-4 md:min-h-[420px] md:px-8 md:pb-8">
      <CardBody item={item} />
    </div>
    {!!item?.description && (
      <>
        <hr className="border-brandBlue-200" />
        <div className="px-4 py-2 md:px-8 md:py-3">
          <CardFooter item={item} />
        </div>
      </>
    )}
  </div>
);

export default Card;
