import { AlgoliaClinicV2 } from '@/types/algolia-types';
import { ELBadge } from '@davincihealthcare/elty-design-system-react';
import { VideoCameraIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';

type OfferOtherOnlineServicesSectionProps = {
  item: AlgoliaClinicV2;
  clinicPageURL: string;
};
const OfferOtherOnlineServicesSection = ({ item, clinicPageURL }: OfferOtherOnlineServicesSectionProps) => {
  const isOfferingAnyOnlineServices = item.offerOnlineServices;
  return isOfferingAnyOnlineServices ? (
    <div className="inline-flex items-center gap-x-2" data-cy="card onlinebadge">
      <ELBadge Icon={VideoCameraIcon} color="primary" size="small" />
      <div>
        <p className="text-sm text-neutral-lighter">
          Offre anche <span className="text-sm font-semibold text-neutral-600">prestazioni online</span>
        </p>
        <Link href={clinicPageURL} legacyBehavior>
          <a href={clinicPageURL}>
            <span className="text-sm font-semibold text-neutral-lighter underline">Scopri di più</span>
          </a>
        </Link>
      </div>
    </div>
  ) : null;
};

export default OfferOtherOnlineServicesSection;
