import { ComponentPropsWithoutRef, useEffect, useState } from 'react';
import clsx from 'clsx/lite';
import { useSelect } from 'downshift';
import { OrderType } from '@/api/algolia';

export type SelectOption = {
  value: string;
  key: OrderType;
};

type SelectProps = Omit<ComponentPropsWithoutRef<'div'>, 'onSelect'> & {
  name: string;
  options: SelectOption[];
  onSelect: (option: string) => void;
  placeholder?: string;
  children?: React.ReactNode;
  initialSelectedItem?: SelectOption;
  'data-cy'?: string;
};

const Select = ({ children, options, onSelect, name, placeholder, initialSelectedItem, ...rest }: SelectProps) => {
  const [items] = useState(options);
  const { isOpen, getToggleButtonProps, getMenuProps, getItemProps, highlightedIndex, selectedItem, selectItem } = useSelect({
    items,
    itemToString: items => items?.value ?? '',
    initialSelectedItem: initialSelectedItem,
    onSelectedItemChange: changes => {
      onSelect(changes?.selectedItem?.value ?? '');
    },
  });

  useEffect(() => {
    if (!initialSelectedItem) return;
    selectItem(initialSelectedItem);
  }, [initialSelectedItem, selectItem]);

  return (
    <label htmlFor={name}>
      <div className="relative">
        <div
          className="flex w-full cursor-pointer items-center justify-between rounded-md border border-neutral-inactive bg-neutral-surface px-3 py-2 text-primary md:min-w-56"
          {...getToggleButtonProps()}
          {...rest}
        >
          <p className="flex items-center">
            <span className="truncate text-xs font-medium text-neutral-lighter">{selectedItem?.value || placeholder}</span>
            <span className="absolute right-3 h-4 w-4 text-primary">{children}</span>
          </p>
        </div>
        <ul
          className={clsx(
            'absolute z-10 mt-1 w-full overflow-auto rounded-md bg-neutral-surface shadow-md md:min-w-56',
            isOpen && 'flex flex-col gap-y-1.5 p-2',
          )}
          {...getMenuProps()}
        >
          {isOpen &&
            items.map((item, index) => {
              return (
                <li
                  key={`${item.value}-${index}`}
                  data-cy={`${rest['data-cy']}-option-${item.value.replaceAll(' ', '-')}`}
                  {...getItemProps({ item, index })}
                >
                  <div
                    className={clsx(
                      'flex w-full cursor-pointer items-center gap-x-2 rounded-md p-2 text-primary',
                      highlightedIndex === index && selectedItem?.value !== item.value && 'bg-neutral-hover text-primary-hover',
                      selectedItem?.value === item.value && 'bg-brandBlue-200',
                    )}
                  >
                    <span className="w-auto truncate text-xs font-medium">{item.value}</span>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </label>
  );
};

export default Select;
