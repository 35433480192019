import { FilterProps } from '@/api/algolia';
import { geocodeBySearchLocation } from '@/api/mapbox';
import { capitalizeWordsInString, getStringFromQueryParam } from '@/utils/string';

export const makeSearchTitle = (service?: string, location?: string) => {
  if (!service && !location) {
    return null;
  }
  if (service && !location) {
    return capitalizeWordsInString(service);
  }
  if (!service && location) {
    return capitalizeWordsInString(location);
  }
  return `${capitalizeWordsInString(service)} - ${capitalizeWordsInString(location)}`;
};

export type SearchLocation = {
  name: string;
  latitude: null | number;
  longitude: null | number;
};

export type MayBeSearchLocation = {
  name?: string | undefined;
  latitude?: string | string[] | undefined;
  longitude?: string | string[] | undefined;
};

export const makeSearchLocation = async (locationInfo?: MayBeSearchLocation): Promise<null | SearchLocation> => {
  const { name: locationName, latitude: locationLatitude, longitude: locationLongitude } = locationInfo || {};

  if (!locationName && !locationLatitude && !locationLongitude) {
    return null;
  }
  const searchLocationName = Array.isArray(locationName) ? locationName[0] : locationName;
  const searchLocationLatitude = Array.isArray(locationLatitude) ? Number(locationLatitude[0]) : Number(locationLatitude);
  const searchLocationLongitude = Array.isArray(locationLongitude) ? Number(locationLongitude[0]) : Number(locationLongitude);
  if (searchLocationLatitude && searchLocationLongitude) {
    return {
      name: searchLocationName,
      latitude: searchLocationLatitude,
      longitude: searchLocationLongitude,
    };
  }
  const { latitude, longitude } = await geocodeBySearchLocation(searchLocationName);
  return {
    name: searchLocationName,
    latitude,
    longitude,
  };
};

export const makeSearchParams = async (
  queryParams: { service: string; location: string; lat: string; lng: string; page: number },
  healthcareServiceExt?: string,
  locationExt?: MayBeSearchLocation,
  filterExt?: FilterProps,
  isSpecializationSearch?: boolean,
) => {
  return {
    searchHealthcareService: healthcareServiceExt ?? getStringFromQueryParam(queryParams.service),
    searchLocation: await makeSearchLocation(
      locationExt ?? {
        name: getStringFromQueryParam(queryParams.location),
        latitude: queryParams.lat,
        longitude: queryParams.lng,
      },
    ),
    searchPage: queryParams.page - 1,
    searchFilter: filterExt,
    isSpecializationSearch,
  };
};

export const clearValue = (value: string) => {
  return value?.replace(/<em>/g, '').replace(/<\/em>/g, '');
};

/**
 * Parse a page query string and return the corresponding page number, with the assumption that the first page is 0 instead of 1.
 *
 * @param pageQueryString An optional parameter that can be a string or an array of strings representing the page query string.
 * @returns The page number as an integer, with the first page being 0 instead of 1
 */
export const parseSearchPageNumberFromQueryString = (pageQueryString?: string | string[]) => {
  const page = parseInt(getStringFromQueryParam(pageQueryString));
  if (!page || page === 1) return 0;
  return page - 1;
};

export const getDeliveryModeFilterFromQueryParameters = (exclude: string[] | string | undefined) => {
  const filter = { excludeDomicilary: false, excludeOnline: false, excludeOnsite: false };
  if (exclude === undefined) return filter;
  filter.excludeOnsite = exclude.includes('onsite');
  filter.excludeOnline = exclude.includes('online');
  filter.excludeDomicilary = exclude.includes('domiciliary');
  return filter;
};
