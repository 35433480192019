import { makeClinicSlug } from './shared';
import cloudflareLoader from './cloudflare';
import { sanitizeImageUrl } from './images';
import { AlgoliaClinicV2, License, SpecialityChannel } from '@/types/algolia-types';

const GMAPS_QUERY_URL = 'https://www.google.com/maps/search/?api=1&query=';

export const getMapLink = (address: string = '') => {
  const addressEncoded = encodeURIComponent(address);
  return `${GMAPS_QUERY_URL}${addressEncoded}`;
};

export const makeMarkersList = (items: AlgoliaClinicV2[]) => {
  const markers = [];
  for (const item of items) {
    if (
      !item?._geoloc?.lat ||
      !item?._geoloc?.lng ||
      !item?.name ||
      !item?.license ||
      item.specializations?.specialityChannels?.includes(SpecialityChannel.Online)
    )
      continue;
    const markerItem = {
      lat: item._geoloc.lat,
      lng: item._geoloc.lng,
      label: item?.name,
      slug: makeClinicSlug(item, item?.specializations?.label ?? ''),
      isLicensed: item.license !== License.None,
      logo: sanitizeImageUrl(cloudflareLoader({ src: item.logoUrl, width: 100 })),
      address: `${item.address}, ${item.cap} ${item.city}`,
    };
    if (item.license !== License.None) markers.push(markerItem);
    else markers.unshift(markerItem);
  }
  return markers;
};

export const generateMapUuid = (items: AlgoliaClinicV2[]) => {
  const ids = [];
  for (const item of items) {
    if (!item?._id) continue;
    ids.push(item?._id);
  }
  return Buffer.from(ids.join(''), 'utf-8').toString('base64');
};
