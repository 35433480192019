type AddressProps = {
  address?: string;
};

const Address = ({ address }: AddressProps) => {
  return address ? (
    <div data-cy="card-address">
      <p className="text-sm font-medium text-neutral-lighter">{address}</p>
    </div>
  ) : null;
};

export default Address;
