import { SkeletonRow } from '@/components/skeletonRow';
import { PaymentType } from '@/graphql/types';
import { CurrencyEuroIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { Maybe } from 'graphql/jsutils/Maybe';
import { ELTooltip } from '@davincihealthcare/elty-design-system-react';
import { formatReservationPrice } from '@/app/(checkout)/_utils/checkout';

const PriceSection = ({
  availablePayments,
  hasChangedSelectedSlot,
  hasDynamicPrice,
  isLoadingAvailabilities,
  isLicensedClinic,
  price,
}: {
  hasChangedSelectedSlot: boolean;
  hasDynamicPrice: boolean;
  isLoadingAvailabilities: boolean;
  price?: number;
  isLicensedClinic: boolean;
  availablePayments: Maybe<PaymentType[]>;
}) => {
  const canShowSkeleton = hasDynamicPrice && isLoadingAvailabilities && hasChangedSelectedSlot;
  const canShowPriceInfo = hasDynamicPrice && !hasChangedSelectedSlot;
  const canShowPaymentInfo = availablePayments?.includes(PaymentType.Onsite);

  const isPriceAvailable = price || price === 0; // Price 0 is ok

  return (
    <div className="flex items-center gap-x-2">
      <CurrencyEuroIcon className="my-auto h-7 w-7 min-w-7 text-primary" />

      {isPriceAvailable && isLicensedClinic ? (
        <div className="flex flex-col gap-1.5 text-nowrap">
          {canShowSkeleton ? (
            <SkeletonRow className="h-5 w-1/2" />
          ) : (
            <div className="flex items-center gap-1 text-sm font-normal text-neutral-darker">
              {canShowPriceInfo && <p>a partire da</p>}

              <p className="text-sm">{formatReservationPrice(price)} &#8364;</p>

              {canShowPriceInfo && (
                <span className="flex-1">
                  <ELTooltip text={'Il prezzo potrebbe variare in base al giorno o orario scelto'}>
                    <InformationCircleIcon className="h-5 w-5 text-primary-active" />
                  </ELTooltip>
                </span>
              )}
            </div>
          )}

          {canShowPaymentInfo && <p className="text-xs text-neutral-lighter">Pagamento in sede disponibile</p>}
        </div>
      ) : (
        'Prezzo non disponibile'
      )}
    </div>
  );
};

export default PriceSection;
