import { DayOfWeek, OpeningHours } from '@/graphql/types';
import type { DayOfWeek as LdDayOfWeek, OpeningHoursSpecification } from 'schema-dts';

export const jsonLdOpeningHoursSpecification = (openingHours: OpeningHours): OpeningHoursSpecification[] => {
  let dayOfWeek: LdDayOfWeek | undefined;
  switch (openingHours.day) {
    case DayOfWeek.Friday:
      dayOfWeek = 'Friday';
      break;
    case DayOfWeek.Monday:
      dayOfWeek = 'Monday';
      break;
    case DayOfWeek.Saturday:
      dayOfWeek = 'Saturday';
      break;
    case DayOfWeek.Sunday:
      dayOfWeek = 'Sunday';
      break;
    case DayOfWeek.Thursday:
      dayOfWeek = 'Thursday';
      break;
    case DayOfWeek.Tuesday:
      dayOfWeek = 'Tuesday';
      break;
    case DayOfWeek.Wednesday:
      dayOfWeek = 'Wednesday';
      break;
  }

  return openingHours.openHours.map(({ from, to }) => {
    return {
      '@type': 'OpeningHoursSpecification',
      dayOfWeek,
      opens: from,
      closes: to,
    };
  });
};
