import errorTranslations from '@/dictionaries/it_errors.json';
import { ApolloError } from '@apollo/client';

export const trackNewError = (error: string) => {
  console.error('New Error ===> ', error);
};

type ErrorCodeType = keyof typeof import('@/dictionaries/it_errors.json');

const isErrorWithTranslationMessage = (error: unknown): error is Error & { message: ErrorCodeType } => {
  return error instanceof Error && error.message in errorTranslations;
};

export const getErrorMessage = (error: unknown) => {
  // TODO Move translation message login to backend
  const message: ErrorCodeType = isErrorWithTranslationMessage(error) ? error.message : 'default';
  return errorTranslations[message] ?? errorTranslations.default;
};

export const getApolloErrorMessageV2 = (error: ApolloError): any => {
  try {
    //TODO TEMP Fix - To handle better errors from backend to frontend
    const jsonString = error.message.match(/{[^{}]*}/)?.[0];

    if (!jsonString) {
      return { errorMessage: error.message };
    }

    const correctedJsonString = jsonString.replace(/"?\b(\w+)\b"?\s*:/g, '"$1":');
    const parsedJson = JSON.parse(correctedJsonString);

    return `${errorTranslations.default}: ${parsedJson.errorMessage}` || parsedJson;
  } catch (e) {
    return { errorMessage: error.message };
  }
};
